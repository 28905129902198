@import "../../../bower_components/bootstrap/less/bootstrap.less";
@import "../../../bower_components/font-awesome/less/font-awesome.less";
@import (less) "../css/animate.css";


// COLOURS

@blue: rgb(35,158,250);
@pink: rgb(204,48,120);
@red: rgb(205,8,20);
@green: rgb(134,190,76);
@grey: rgb(231,231,231);
@orange: rgb(253,163,54);


@hp-banner-height: 450px;

// FONTS

      @font-face {
        font-family: 'Amplitude';
        src: url('../fonts/Amplitude-Light.eot');
        font-weight: 100;
       }

      @font-face {
        font-family: 'Amplitude';
        src: url('../fonts/Amplitude-Light.woff') format('woff'),
        url('../fonts/Amplitude-Light.svg#Amplitude-Light') format('svg');
        font-weight: 100;
       }


      @font-face {
        font-family: 'Amplitude';
        src: url('../fonts/Amplitude-Book.eot');
        font-weight: 200;
       }

      @font-face {
        font-family: 'Amplitude';
        src: url('../fonts/Amplitude-Book.woff') format('woff'),
        url('../fonts/Amplitude-Book.svg#Amplitude-Book') format('svg');
        font-weight: 200;
       }


      @font-face {
        font-family: 'Amplitude';
        src: url('../fonts/Amplitude-Regular.eot');
        font-weight: 300;
       }

      @font-face {
        font-family: 'Amplitude';
        src: url('../fonts/Amplitude-Regular.woff') format('woff'),
        url('../fonts/Amplitude-Regular.svg#Amplitude-Regular') format('svg');
        font-weight: 300;
       }


      @font-face {
        font-family: 'Amplitude';
        src: url('../fonts/Amplitude-Medium.eot');
        font-weight: 400;
       }

      @font-face {
        font-family: 'Amplitude';
        src: url('../fonts/Amplitude-Medium.woff') format('woff'),
        url('../fonts/Amplitude-Medium.svg#Amplitude-Medium') format('svg');
        font-weight: 400;
       }


      @font-face {
        font-family: 'Amplitude';
        src: url('../fonts/Amplitude-Bold.eot');
        font-weight: 600;
       }

      @font-face {
        font-family: 'Amplitude';
        src: url('../fonts/Amplitude-Bold.woff') format('woff'),
        url('../fonts/Amplitude-Bold.svg#Amplitude-Bold') format('svg');
        font-weight: 600;
       }


      @font-face {
        font-family: 'Amplitude';
        src: url('../fonts/Amplitude-Black.eot');
        font-weight: 800;
       }

      @font-face {
        font-family: 'Amplitude';
        src: url('../fonts/Amplitude-Black.woff') format('woff'),
        url('../fonts/Amplitude-Black.svg#Amplitude-Black') format('svg');
        font-weight: 800;
       }




// STYLES


body {
    font-family: "Amplitude";
    font-weight: 200;
    font-size: 16px;
}


h1, h2, h3, h4, h5, h6 {
    font-family: "museo";
    font-weight: 500;
    margin: 0;
}

p {
    margin-top: 0;
}

strong , label {
    font-weight: 400;
}

label {
    margin-top: 5px;
    margin-bottom: 0px;
}

.img-responsive.img-centred {
    margin: 0 auto !important;
    padding: 0 auto !important;
}

header {
    background-color: @green;
    padding-bottom: 20px;
    color: #FFFFFF;

    nav {
        &.navbar-default {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            height: 60px;
            margin: 20px 0;
            padding: 0;

            .navbar-brand {
                height: 60px;
                padding: 0;
                margin: 0;
            }

            .navbar-nav {
                li{

                    a{
                        color: rgba(255,255,255,1);
                    }
                    a:hover{
                        color: rgba(0,0,0,1);
                    }
                }
            }
        }

        img {
            width: 351px;
            height: 60px;
        }


    }

    form {

        input {
            width: 350px;
            border: none;
            border-radius: 4px;
            font-family: "Amplitude";
            line-height: 36px;
            padding: 0 10px;
            color: @green;
            outline:none;
        }
    }






    .cartlabel {

        color: #FFFFFF;

        a {
            color: #FFFFFF;
            text-decoration: none;
        }

        span {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 50%;
            background: #FFFFFF;
            color: @green;
            text-align: center;
        }


        p {
            margin-top: 8px;
        }
    }
}

.align_right{
    text-align: right !important;
}

.page_title{
    border-bottom: 1px solid #cd0814;
    padding-bottom: 10px;
    margin-bottom: 30px;
}

.content_header_image_holder{
    position: relative;
    height: @hp-banner-height;
    overflow: hidden;

    .content_header_image.header_bg{
        display: -webkit-flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -webkit-box-direction: normal;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        background-size: cover;
        background-position: center center;
        position: absolute;
        width: 100%;
        height: @hp-banner-height;

        #banner_hp_1,
        #banner_hp_2 {
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-delay: 1s;
            animation-delay: 1s;
            -webkit-animation-iteration-count: 1; /* Safari and Chrome */
            animation-iteration-count: 1;
        }
        .col-md-12{
            -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
            -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
            -webkit-animation-iteration-count: 1; /* Safari and Chrome */
            animation-iteration-count: 1;
        }
    }
}


.bg_black {
    margin-bottom: 40px;
    background: #000000;
}

.content_page_header {
    color: #FFFFFF;

    a{
        color: #FFFFFF;
    }

    a:hover{
        text-decoration: none;
    }

    .logo img {
        height: 80px;
        margin: 10px 0;
    }
    h2 {
        font-size: 60px;
        color: #FFFFFF;
        margin: 10px 0;
    }
    h4{
        font-size: 20px;
        font-weight: 100;
        color: #FFFFFF;
        margin: 10px 0;
    }
}

.store_body_content {

    padding-top: 60px;

    h3{
        padding: 20px 0;
        border-bottom: 1px solid @green;
        margin: 30px 0;
        font-weight: 400;
    }

    .onsale_lg.on-sale {
        background: @pink;
        color: #FFFFFF;
        width: 100%;
        display: block;
        line-height: 2.6em;
        border-radius: 2.6em;
        margin-bottom: 1em;
    }

    .onsale_sm.on-sale {
        background: @pink;
        color: #FFFFFF;
        width: 100%;
        font-size: 0.6em;
        display: block;
        line-height: 2.6em;
        border-radius: 2.6em;
        margin-bottom: 1em;
    }

}

.store_item {
    height: 390px;
    text-align: center;
    h4 {
        font-family: "Amplitude";
        color: #000000;
        font-size: 16px;
        font-weight: 200;
        margin-top: 8px;
        height: 50px;
        overflow: hidden !important;

        a {
            color: #000000;
        }
    }

    .book_cover {
        height: 230px;
        width: 100%;
        display: table;

        a.image_border {
            display: table-cell;
            vertical-align: bottom;
        }
        img {

        }


    }

}

.cover_image {
    text-align: center;
    img {
        margin: auto ;
        padding-bottom: 20px;
    }
}

.relative {
    position: relative;
    height: 45px;
}



.price_buy {
    position: absolute;
    bottom: 60px;
    left: 15px;
    right: 15px;
    border-top: 1px solid @green;
    border-bottom: 1px solid @green;
    margin-bottom: 0px;
    padding: 6px 0;
    color: @green;
    text-align: left;

    p {
        margin: 0;
    }
    a {
        float: right;
        text-align: right;
        color: @green;
    }
    span {
        display: block;
        font-size: 12px;
        margin: 0;
        padding: 0;
    }
}

.sale_items {

    .price_buy {
        bottom: 40px !important;
    }

}

.item_info_panel {

    .price_buy {
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }


    .item_fine_details_panel {
        padding-top: 14px;
        font-size: 14px;
        border-radius: 4px;
        font-weight: 200;
        .row {
            border-bottom: 1px solid #3d3d3d;
            margin: 0 0 14px 0;
        }
        .col-md-8 {
            text-align: right;
            padding: 0;
        }
        .detail_title {
            font-weight: 400;
            margin: 0;
            padding: 0;
        }


    }

    .mediaenquiries {
        margin-top: 80px;
        font-size: 12px;
        strong {
            font-weight: 400;
        }
    }

    .btn {
        width: 147px;
    }

    .buttonbar {
        margin: 20px 0 10px 0;
        text-align: center;
    }

}

.ibooks {
    text-align: left;
    a {
        display: block;
    }
}


.item_detail_panel {

    h2 {
        border-bottom: 1px solid @green;
        margin-bottom: 30px;
        padding-bottom: 10px;
    }

    h3{
        padding-top: 10px;
        padding-bottom: 10px;
    }

    h4 {
        margin: 20px 0 10px 0;
    }

    .media_item:first-of-type h4 {
            margin: 0px 0 10px 0;
    }

}

.sidebar {
    .store_item {
        width: 100%;
        height: inherit !important;
        margin-bottom: 30px;

        .book_cover {
            height: inherit !important;
            img {
                position: relative !important;
            }
        }
    }

    .price_buy {
        margin-bottom: 0px;
    }

    h3 {
        margin: 0;
        padding-top: 0;
        line-height: 33px;
    }
}


h4.colheader {
    padding-top: 0px;
    padding-bottom: 10px;
    border-bottom: 1px solid @green;
    margin-bottom: 30px;
    line-height: 33px;
}

.sidefeature {
    h4.colheader {
        padding-top: 0px;
        padding-bottom: 10px;
        border-bottom: 1px solid @green;
        margin-bottom: 30px;
        line-height: 33px;
    }

    .price_buy {
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin-top: 8px;
    }


}

.contact-form{
    font-weight: 200;

    label{
        font-weight: 200;
        padding-top: 0px !important;
        strong{
            font-weight: normal;
        }
    }
}

.contact {
    background: @grey;
    padding-top: 30px;
    margin: 40px 0 0 0;

    h3 {
        padding-bottom: 20px;
    }

    .contactdetail {
        padding-bottom: 40px;
    }

    #map-canvas {
        width: 100%;
        height: 480px;

    }
}


.logo-bleck{
    height: 50px;
    margin-top: 20px;
    margin-bottom: 10px;
}


.postaldetails, .paymentdetails {
    background: #efefef;
    border-radius: 6px;
    margin: 0 0 40px 0;

    legend {
        margin-top: 20px;
    }

    .checkbox {
        margin-left: 20px;
    }

}

.cart_page {

    h3 {
        margin-top: 0;
        padding: 0;
        line-height: 50px;
    }

    .row {
        margin-bottom: 10px;

        display: flex;
        align-items: center;
        box-align: center;
        flex-direction: normal;
        box-pack: center;
        justify-content: center;

    }
    .remover {
        float: right;
        color: #cccccc;
        padding: 4px;
    }
    .remover:hover {
        float: right;
        color: #888888;
    }
    #coupon_notice {
        display: none;
    }

}

legend {
    border-bottom-color: #9C9C9C;
}

.search_form_results {
    margin: 0 0 20px;
    background: #efefef;
    border-radius: 6px;
    padding: 20px;

    .help-block {
        text-align: center;
        margin: 0;
        font-size: 0.9em;
    }
}